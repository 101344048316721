<template>
	<div class="home">
		<div style="text-align: center;">
			<div>客户姓名【{{arrCustomer.name}}】- 所属客服【{{arrCustomer.user_name}}】- 当前作品【{{nWorkNum}}/{{worksList.length}}】</div>
			<div></div>
		</div>

		<div class="ipt-left">
			<div class="right-content">
				<div class="right-content" :class="{center: isCenter}" ref="imgCanvas" :style="'background-image: url(' + activity.background_img_path + ')'" >
					<VueDragResize :style="size + ';' + activity.color + ';' + pTop + ';' + pLeft + '; font-family: 楷体;'" :isActive="false" :w="388" :h="75" class="resize" :x="0" :y="160" v-on:resizing="resize"
						v-on:dragging="resize" :sticks="['bm']">
						<p>{{workInfo.content}}</p>
					</VueDragResize>
				</div>
			</div>
		</div>

		<div class="ipt-right" style="float: left;  width: 40%;">
			<div style="padding: 20px; text-align: center;">
				<el-button type="info">智能排版</el-button>
				<el-button @click="isCenter = !isCenter"  type="primary">{{isCenter?'靠左对齐':'居中对齐'}}</el-button>
				<el-button @click="toImage" type="success" :loading="subLoading" :disabled="subLoading">保存作品</el-button>
			</div>
			<el-input type="textarea" :rows="14" placeholder="请输入内容" v-model="workInfo.content" style="width: 100%; height: 80%;"></el-input>
		</div>
	</div>
</template>

<script>
	import oConfig from '@/js/config.js';
	import VueDragResize from 'vue-drag-resize';
	import html2canvas from 'html2canvas';

	export default {
		name: 'Home',
		components: {
			VueDragResize
		},
		props: {
			workInfo: {
				type: Object,
				default: {},
			},
			activityId: {
				type: String,
				default: '',
			},
			arrCustomer: {
				type: Object,
				default: {},
			},
			worksList:{
				type:Object,
				default: {},
			}
		},
		data() {
			return {
				activity:{},
				sTitle:'', // 标题信息
				size: 'font-size:' + 15 + 'px', // 内容默认字号
				pTop: 'padding-top:' + 40 + 'px; ' + 'padding-bottom:' + 40 + 'px;',
				pLeft: 'padding-left:' + 30 + 'px;' + 'padding-right:' + 30 + 'px;',
				isCenter: true,// 拖拽内容

				nTitleSize: 'font-size:' + 15 + 'px',
				nTitleTop: 'padding-top:' + 40 + 'px; ' + 'padding-bottom:' + 40 + 'px;',
				nTitleLeft: 'padding-left:' + 30 + 'px;' + 'padding-right:' + 30 + 'px;',
				
				nScale:1.2, // 缩放比例
				subLoading:false, // 提交按钮的loading
				nWorkNum:1, // 以完成作品数量
			}
		},
		created() {
			this.getActivity()
			if (this.workInfo.content.length > 100) {
				this.nScale = 1.3;
			}
			if (this.workInfo.content.length > 150) {
				this.nScale = 1.4;
			}
			if (this.workInfo.content.length > 200) {
				this.nScale = 1.5;
			}
			for (let i=0; i<= this.worksList.length; i++) {
				if(this.worksList[i].step == 2) {
					this.nWorkNum ++;
				}
			}
		},
		
		methods: {
			/**
			 * 获取活动信息
			 */
			getActivity(){
				let data = {
					id : this.activityId,
				}
				let url = "admin/crm.activity/getInfo"
				oConfig.request(url, data).then((res) => {
					res.color = 'color:'+res.font_color
					this.activity = res
				})
			},
			/**
			 * 生成图片
			 */
			toImage() {
				this.subLoading = true;
				const opts = {
					backgroundColor: null,
				    useCORS: true ,// 【重要】开启跨域配置
					scale: this.nScale,
				};
				html2canvas(this.$refs.imgCanvas, opts).then((canvas) => {
					let img = canvas.toDataURL('image/png');
					let data = {
						id:this.workInfo.id,
						file:img
					}
					let url = "admin/crm.works/upWorkFile"
					oConfig.request(url, data).then((res) => {
						this.$emit('closeShowWork');
						this.subLoading = false;
					})
				});
			},
			resize(newRect) {
				this.size = 'font-size:' + newRect.height / 5  + 'px'
			},
			resizeTitle(newRect) {
				this.nTitleSize = 'font-size:' + newRect.height / 5  + 'px'
			}
		},
		watch: {

		}
	}
</script>

<style>
	.home {
		height: 100vh;
	}

	.home::after {
		display: block;
		content: "";
		clear: both;
	}

	.ipt-left {
		float: left;
		width: 50%;
		height: 100%;
	}

	.ipt-left::after {
		display: block;
		content: "";
		clear: both;
	}

	.el-color-picker {
		float: left;
		margin-left: 20px;
	}

	.el-textarea {
		margin-top: 20px;
	}

	.el-color-picker__trigger {
		width: 50px !important;
	}

	.ipt-right {
		position: relative;
	}

	.right-content {
		width: 450px;
		height: 800px;
		box-sizing: border-box;
		word-wrap: break-word;
		word-break: break-all;
		text-align: left;
		white-space: pre-wrap;
		font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
			"Microsoft YaHei", "微软雅黑", Arial, sans-serif;
			background-size: cover;
	}

	.center {
		text-align: center;
	}

	.el-textarea__inner{
		font-size: 20px;
	}
</style>
